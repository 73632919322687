<template lang="pug">
.main-wrapper.caixas-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "sala" }'> Sala</router-link> /
                        <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Sala`")
                    .p-grid.p-fluid.p-align-start(style='margin: 0 auto; max-width: 500px;')
                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ie_tipo.$error }")
                            label.form-label Tipo:
                            Dropdown(v-model='$v.model.ie_tipo.$model' :options='options.tipo'
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='true')
                            .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                                .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Tipo</b> é obrigatório.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nm_sala.$error }")
                            label.form-label Descrição:
                            InputText(type='text' v-model='$v.model.nm_sala.$model')
                            .feedback--errors(v-if='submitted && $v.model.nm_sala.$error')
                                .form-message--error(v-if="!$v.model.nm_sala.minLength") <b>Descrição</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.nm_sala.required") <b>Descrição</b> é obrigatório.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_pai.$error }")
                            label.form-label Setor:
                            Dropdown(v-model='$v.model.cd_pai.$model' :options='options.setores'
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='true')
                                template(#option='props')
                                    span <b> Setor: {{ props.option.text }} </b> <br>
                                        span <b> Unidade: </b> {{ props.option.textAlt }}
                            .feedback--errors(v-if='submitted && $v.model.cd_pai.$error')
                                .form-message--error(v-if="!$v.model.cd_pai.required") <b>Setor</b> é obrigatório.

                        .p-col-12
                            label.form-label Estabelecimento:
                            Dropdown(v-model='model.cd_estabelecimento' :options='options.estabelecimentos'
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='true')

                        .p-col-6.p-md-4.ta-center
                            label.form-label Restrito ao sistema:
                            InputSwitch(v-model='model.ie_restrito')

                        .p-col-6.p-md-4.ta-center
                            label.form-label Habilitado:
                            InputSwitch(v-model='model.ie_status')

                        .p-col-12.mt-2(v-if="model.id")
                            .p-grid
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                        .p-col-12.mt-2
                            Button(label='Salvar' type="submit")
</template>

<style lang="scss">
    .caixas-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import InputNumber from 'primevue/inputnumber'
    import InputSwitch from 'primevue/inputswitch'

    import { UnidadesMedclub, Estabelecimento, DominioValor } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, InputNumber,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect, InputSwitch
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true

            this.getSetores()
            this.getEstabelecimentos()
            this.getDominio()

            let id = null
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id) {
                UnidadesMedclub.findSala(id).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)
                        
                        keys.forEach(key => this.model[key] = response.data[key])
                        
                        this.model.id = id
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        this.model.nm_usuario_cri = response.data.nm_usuario_cri
                        if (response.data.dt_atualizado) {
                            this.model.nm_usuario_edi = response.data.nm_usuario_edi
                            this.model.dt_atualizado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        }
                    }
                    this.waiting = false
                })
            } else this.waiting = false     
        },
        data () {
            return {
                model: {
                    cd_pai: null,
                    nm_sala: '',
                    nm_unidade: '',
                    ie_restrito: false,
                    ie_classificacao: 'SET',
                    cd_estabelecimento: null,
                    ie_status: true,
                    ie_tipo: null,
                },
                options: {
                    tipo: [],
                    setores: [],
                    estabelecimentos: [],
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    nm_sala: { required, minLength: minLength(2) },
                    cd_pai: { required },
                    ie_tipo: { required },
                }
            }
            return v
        },
        methods: {
            getDominio(){
                DominioValor.findAll({ds_mnemonico: 'TIPO_SALA'}).then(response => {
                    if (response.status === 200) {
                        response.data['TIPO_SALA'].valores.forEach(item =>
                            this.options.tipo.push({text: item.ds_valor, value: item.ie_valor})
                        )
                    }
                })
            },
            getEstabelecimentos(){
                Estabelecimento.findAllClean({order: 'nm_fantasia'}).then(response => {
                    if (response.status === 200) {
                        response.data.forEach(item => 
                            this.options.estabelecimentos.push({text: item.nm_fantasia, value: item.id})
                        )
                    }
                })
            },
            getSetores (){
                UnidadesMedclub.findAllSetor().then(response=>{
                    if (response.status == 200) {
                        response.data.forEach(item => {
                            this.options.setores.push({text: item.nm_setor, textAlt:item.nm_unidade, value: item.id})
                        })
                    }
                })
            },
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                this.model.nm_unidade = this.model.nm_sala
                delete this.model.nm_sala

                let dataSend = Object.assign({}, this.model)

                this.waitingForm = true
                UnidadesMedclub.saveSala(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$router.push({ name: 'sala' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })
            }
         }
      }
</script>